import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom'; // auth
import {AuthGuard} from 'src/auth/guard'; // layouts
import DashboardLayout from 'src/layouts/dashboard'; // components
import {LoadingScreen} from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// MY PRODUCT
const MyProductListPage = lazy(() => import('src/pages/dashboard/my-product/list'));
const MyProductNewPage = lazy(() => import('src/pages/dashboard/my-product/new'));

const MyOrderNewPage = lazy(() => import('src/pages/dashboard/my-order/new'));
const MyOrderListPage = lazy(() => import('src/pages/dashboard/my-order/list'));
const MyOrderEditPage = lazy(() => import('src/pages/dashboard/my-order/edit'));

const PrintOrderPage = lazy(() => import('src/pages/dashboard/print/print-order-page'));
const OrderAmountReportPage = lazy(() => import('src/pages/dashboard/report/order-amount-report'));
const OrderCountByProductAndQtyReportPage = lazy(() =>
  import('src/pages/dashboard/report/order-count-by-product-and-qty-report')
);

const DealerListPage = lazy(() => import('src/pages/dashboard/dealer/list'));
const SkuListPage = lazy(() => import('src/pages/dashboard/sku/list'));
const SkuEditPage = lazy(() => import('src/pages/dashboard/sku/edit'));
const SkuNewPage = lazy(() => import('src/pages/dashboard/sku/new'));
const StockListPage = lazy(() => import('src/pages/dashboard/stock/list'));
const StockHistoryPage = lazy(() => import('src/pages/dashboard/stock/history'));
const QuotaListPage = lazy(() => import('src/pages/dashboard/quota/list'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <MyProductListPage />, index: true },
          { path: 'list', element: <MyProductListPage /> },
          { path: 'new', element: <MyProductNewPage /> },
        ],
      },
      {
        path: 'orders',
        children: [
          { element: <MyOrderListPage />, index: true },
          { path: 'new', element: <MyOrderNewPage /> },
          { path: ':id/edit', element: <MyOrderEditPage /> },
        ],
      },
      {
        path: 'reports',
        children: [
          { element: <OrderAmountReportPage />, index: true },
          { path: 'order-amount', element: <OrderAmountReportPage /> },
          {
            path: 'order-count-by-product-and-quantity',
            element: <OrderCountByProductAndQtyReportPage />,
          },
        ],
      },
      {
        path: 'dealers',
        children: [
          { element: <DealerListPage />, index: true },
          { path: ':id/edit', element: <DealerListPage /> },
        ],
      },
      {
        path: 'sku',
        children: [
          { element: <SkuListPage />, index: true },
          { path: ':id/edit', element: <SkuEditPage /> },
          { path: 'new', element: <SkuNewPage /> },
        ],
      },
      {
        path: 'stock',
        children: [
          { element: <StockListPage />, index: true },
          { path: 'history/:id', element: <StockHistoryPage /> },
        ],
      },
      {
        path: 'quota',
        children: [{ element: <QuotaListPage />, index: true }],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'print/orders', element: <PrintOrderPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
