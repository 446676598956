import axios from 'axios';
// config
import {HOST_API, HOST_API_SAMPLE, TENANT_CONFIG} from 'src/config-global';

// ----------------------------------------------------------------------

const myAxiosInstance = axios.create({ baseURL: HOST_API });

myAxiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default myAxiosInstance;

//
export const myFetcher = async (url) => {
  try {
    const response = await myAxiosInstance.get(url, {
      headers: {
        'oms-tenant-id': TENANT_CONFIG.id,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

//
export const poster = async (url, payload) => {
  const res = await myAxiosInstance.post(url, payload, {
    headers: {
      'oms-tenant-id': TENANT_CONFIG.id,
    },
  });

  return res.data;
};

//
export const getter = async (url, params) => {
  const queryParams = new URLSearchParams(params).toString();

  const res = await myAxiosInstance.get(`${url}?${queryParams}`, {
    headers: {
      'oms-tenant-id': TENANT_CONFIG.id,
    },
  });

  return res.data;
};

//
export const putter = async (url, payload) => {
  const res = await myAxiosInstance.put(url, payload, {
    headers: {
      'oms-tenant-id': TENANT_CONFIG.id,
    },
  });

  return res.data;
};

//
export const deleter = async (url) => {
  const res = await myAxiosInstance.delete(url, {
    headers: {
      'oms-tenant-id': TENANT_CONFIG.id,
    },
  });

  return res.data;
};

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await myAxiosInstance.get(`${HOST_API_SAMPLE}${url}`, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  products: {
    list: '/api/products',
    create: '/api/products',
    update: '/api/products',
  },
  skus: {
    list: '/api/skus',
    transferQuota: '/api/skus/quotas/transfer',
  },
  stocks: {
    list: '/api/stocks',
  },
  customers: {
    search: '/api/customers/search',
    shippingAddress: {
      search: '/api/customers/shipping-address/search',
    },
  },
  orders: {
    create: '/api/orders',
    update: '/api/orders',
    list: '/api/orders',
    search: '/api/orders/search',
    print: '/api/orders/print',
  },
  users: {
    list: 'api/users',
    update: 'api/users',
  },
  dealers: {
    list: 'api/dealers',
    update: 'api/dealers',
  },
  reports: {
    ordersTotalAmount: '/api/reports/order-and-total-amount',
    ordersCountByProductAndQty: '/api/reports/orders-group-by-product-and-qty',
  },
};
